<template>
  <div class="hello">
    <div class="worl">
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Authority',
   data () {
    return {
      appid: this.Global.appid,
      url:this.Global.nowurl,
      route:'/Authority',
      redirectUrl: '',
      code: '',
      z_id:''
    }
  },
  created(){
    this.code = this.Global.getQueryString('code')
    if (!this.code) {
      // 没有code，跳转微信授权获取
      this.redirectUri = encodeURIComponent(`${this.url}${this.route}`)
      location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${this.redirectUri}&response_type=code&scope=snsapi_base#wechat_redirect`
    } else {
      // 有code，获取userid  有token 就退出 无token再获取
      this.code = this.Global.getQueryString('code')
      this.Global.setCookie('code',this.code, this.Global.expires_in)
      this.z_id = window.localStorage.getItem("z_id") 
      let _this = this;
      this.axios({
          method: 'GET',
          url: '/scrm/auth?code='+_this.code
      }).then((res) => {
        if(res.data.code == 0){
          // 设置cookie
          _this.Storage.set('baseInfo', res.data.data)
          // let url = window.localStorage.getItem("beforeLoginUrl") || '/';
          let url = '/CircleOfFriends?z_id='+this.Storage.get('z_id');
          _this.$router.push(url);
        }else{
          _this.$toast(res.data.message);
          _this.Global.closeWindowNow()
          location.href="about:blank";
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.worl{
  span{
    color: red;
    font-size: 0.12rem;
  }
}

</style>
